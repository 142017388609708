<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div>
            <CSelect
                  label="Listeleme Tipi"
                  type="text"
                  horizontal
                  placeholder="Seçiniz"
                  :options="types"
                  :value.sync="type"
                  @change="iysSubsList"
                  required
              />
            </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="iysSubs"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            v-on:refresh="filterIYSSubs"
          >
            <template #emailValidFlag="{item}">
              <td>
                <CIcon
                  v-if="item.emailValidFlag"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #activeFlag="{item}">
              <td>
                <CIcon
                  v-if="item.activeFlag"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #createTime="{item}">
              <td>
                {{ item.createTime && dateFormat(item.createTime) }}
              </td>
            </template>
            <template #emailValidationTime="{item}">
              <td>
                {{ item.emailValidationTime && dateFormat(item.emailValidationTime) }}
              </td>
            </template>
            <template #lang="{item}">
              <td>
                {{ item.lang && item.lang.name }}
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import moment from 'moment'

export default {
  name: 'IYSSub',
  data() {
    return {
      type: "approved",
      types:[
        {value:"approved", label: "Onay Bildirimi Yapılacak Aboneler"},
        {value:"cancelled", label: "İptal Bildirimi Yapılacak Aboneler"},
      ],
      fields: [
        { key: 'createTime', label: 'Kayıt Tarihi', _style: 'font-size:12px' },
        {
          key: 'emailValidFlag',
          label: 'Email Onayı Verildimi?',
          _style: 'font-size:12px'
        },
        {
          key: 'emailValidationTime',
          label: 'Email Onay Tarihi',
          _style: 'font-size:12px'
        },
        { key: 'lang', label: 'Dil', _style: 'font-size:12px' },
        { key: 'ipAddress', label: 'IP Adresi', _style: 'font-size:12px' }
      ],
     
    }
  },

  computed: {
    iysSubs(){
      return this.$store.getters.iysSubs
    },
    loading(){
      return this.$store.getters.notificationLoading
    }
  },
  methods: {

    rowClicked(item) {
      this.lastItem = item
    },

    dateFormat(data){
      return moment(data).format('DD.MM.YYYY hh:mm:ss')
    },


    async iysSubsList() {

      let params = {
        type: this.type
      }
      await this.$store.dispatch('iysSubs_list', params)

    },

  },
  created() {
    this.iysSubsList();
  }
}
</script>
